import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Langel: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Langel,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Langel"
          d="M669.2,1058.5c-0.7,0.5-5.6,3.5-6,4.1l-0.2-0.5c0.1-0.3,0.4-1.2,0.4-1.5l-3.5-0.5l-0.7,2.8 c-4.6-0.6-10.1-0.8-14.1-3.4l-1.7,5.5c-0.4-0.3-11.9-6.2-12.3-6.5l-2.5,3.4c-3.6-2.5-10-6.2-13.6-8.7l-0.8-4.1l-0.9-0.6l-0.1-0.5 l-4-3.4l3.2-4.3c-0.9-1.5-7.4-6.4-8.6-7.8l0.2-1.5c12.8-9.2,28.5-19.4,43-29.1c5.4-3.6,10.6-7.4,15.5-11.6c0.9-0.8,1.7-1.7,2.6-2.6 c4.5-4.5,8.2-8.3,12.8-12.6L691,960l33,40l8-8l8,19.9c-0.3,0-0.7,0-1-0.2c-0.1,3.4-1.1,7.3-0.1,10.6c-4.6-0.4-14.2-1.5-18.8-1.5 c-0.3,0.2-0.4,12.8-0.5,13.1c-1.9,3.9-8.1,15.3-9.8,19.3c-3.3-0.6-13.4-1.4-16.8-1.9c-1,2-2.8,5.6-3.6,7.7c-1.1,0-3.3-0.3-4.4-0.5 l-4.2-8l-0.3-0.6C677.3,1050.7,671.9,1056.8,669.2,1058.5z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 654.0981 1025.7404)"
        >
          Langel
        </text>
      </g>
    </>
  );
});
